<template>
  <v-container fluid class="pt-6 mt-6">
    <v-row justify="center">
      <v-col md="6" lg="5">
        <div class="quota-header">
          <div class="quota-header__back">
            <v-btn fab dark color="info" x-small text @click="goBack">
              <IconPictoAarrowsLeft />
            </v-btn>
          </div>
          <div class="quota-header__title">Faites votre demande</div>
          <div class="quota-header__description">
            Un conseiller va prochainement traiter la validation du devis
          </div>
        </div>
        <div v-if="currentProject" class="quota-info">
          <div class="quota-info__header">
            <div class="total">
              <div class="total-icon">
                <IconMoneyAccountingCalculator />
              </div>
              <div class="total-body">
                <div class="total-body__title">Prix total du projet</div>
                <div class="total-body__subtitle">{{ total }} € TTC</div>
              </div>
            </div>
          </div>
          <div class="quota-info__description">
            <p>Il concerne votre projet : {{ quotaName }}</p>
            <p>
              {{ currentProject.address }}
              <br />
              {{ currentProject.city }}
            </p>
            <p>pour un bien de {{ currentProject.square }} m2</p>
          </div>
          <div class="quota-info__footer">
            <v-chip-group column>
              <v-chip
                v-for="(chip, index) in quotaRooms"
                :key="`chip-${index}`"
                color="primary"
                outlined
              >
                {{ chip.name }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="7" lg="6">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn
              color="info"
              depressed
              @click="$router.push({ name: 'Home' })"
            >
              RETOUR A LA PAGE PROJET
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              :loading="sendButtonLoading"
              color="primary"
              depressed
              @click="sendToManager"
            >
              ENVOYER AU CONSEILLER
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QuotaService } from "@/services/quotas.service.js";
import { ProjectService } from "@/services/project.service.js";
import quotaListMixin from "@/mixins/quotasList.js";
export default {
  name: "QuotaPreValidation",
  mixins: [quotaListMixin],
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
    IconMoneyAccountingCalculator: () =>
      import("~cp/Icons/IconMoneyAccountingCalculator"),
  },
  data() {
    return {
      sendButtonLoading: false,
    };
  },
  computed: {
    quotaRooms() {
      let quota = this.quotas.find(
        (quota) => quota.id === +this.$route.params.quotaId
      );
      return quota ? quota.quota_rooms : [];
    },
    total() {
      let price = this.$store.getters["quota/getTotal"];
      return price.toFixed(2);
    },
    quotaName() {
      return (
        (this.$store.getters["quota/getCurrentQuota"] &&
          this.$store.getters["quota/getCurrentQuota"].name) ||
        ""
      );
    },
  },
  methods: {
    sendToManager() {
      const { id, name, project_id, status } =
        this.$store?.state?.quota?.currentQuota;
      if (!id) return false;
      this.sendButtonLoading = true;
      QuotaService.updateQuotaById(id, {
        name,
        project_id,
        edit: false,
        custom_status: 3,
      })
        .then(({ data }) => {
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.sendToManager")
          );
          this.createProjectHistory();
        })
        .catch((response) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => (this.sendButtonLoading = true));
    },
    createProjectHistory() {
      ProjectService.createProjectHistory({
        quota_id: this.$route.params.quotaId,
      })
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch((ex) => console.log(ex));
    },
    goBack() {
      this.$router.push({
        name: "QuotaAddDocument",
        params: {
          quotaId: this.$route.params.quotaId,
        },
      });
    },
  },
};
</script>

<style src="./QuotaPreview.scss" lang="scss" scoped></style>