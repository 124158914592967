import { ProjectService } from "@/services/project.service.js";
import { QuotaService } from "@/services/quotas.service.js";
import { UserService } from "@/services/user.service.js";

export default {
  data() {
    return {
      rerenderQuotas: 0,
      projects: null,
      quotas: [],
      rooms: []
    }
  },
  computed: {
    currentProjectId() {
      return this.$store.getters['project/getCurrentProjectId']
    },
    currentProject() {
      return this.$store.getters['project/getCurrentProject']
    },
  },
  watch: {
    "$store.state.project.currentProject"(newVal) {
      if (!newVal) this.quotas = []
      this.getQuotas(newVal?.id)
    }
  },
  methods: {
    getProject() {
      return ProjectService.getProjects()
        .then(({ data }) => {
          if (data.length > 0) {
            this.projects = data;
            this.$store.commit("project/SET_PROJECTS", this.projects);
            this.getQuotas(this.$store.getters['project/getCurrentProjectId']);
          }
        })
        .catch((ex) => {
          console.log(ex)
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => this.$store.commit("project/SET_LOADED_PROJECT"))
    },
    getQuotas(projectId) {
      if (!projectId) return;
      QuotaService.getQuotas(projectId)
        .then(({ data }) => {
          this.quotas = data.splice(0, 2)
          this.rooms = []
          this.quotas.forEach((quota) => {
            if (quota.quota_rooms) {
              this.rooms.push(...quota.quota_rooms)
            }
          })
          this.rerenderQuotas++

        })
        .catch((ex) => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        });
    },
    getUsers() {
      if (!this.$store.getters['authModule/isSuperUser']) return
      return UserService.getUsers().then(({ data }) => {
        this.$store.commit("project/SET_USERS", data);
      })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        });
    }
  },
  created() {
    this.$store.commit("SET_LOADER_STATUS", true);
    this.$store.dispatch('quota/getStatuses')
    Promise.all([
      this.getProject(),
      this.getUsers()
    ]).finally(() => this.$store.commit("SET_LOADER_STATUS", false));
  },
}